body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.App {
}

.Header {
  background: #f27a34;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
  padding: 15px 25px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999999999999;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;


  h1 {
    font-family: coquette, san-serif;
    font-weight: 700;
    padding: 0px;
    margin: 0px;
  }
  
  .stats {
    .traveled {
      opacity: .9;
    }
    .total {
      font-weight: bold;
    }
  }
}