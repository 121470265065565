body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0; }

.Header {
  background: #f27a34;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 15px 25px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999999999999;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Header h1 {
    font-family: coquette, san-serif;
    font-weight: 700;
    padding: 0px;
    margin: 0px; }
  .Header .stats .traveled {
    opacity: .9; }
  .Header .stats .total {
    font-weight: bold; }

